import { Routes } from '@angular/router';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: 'start/territory/1',
    pathMatch: 'full',
  },
  {
    path: 'i/:code/reschedule',
    loadComponent: () => import('./screens/manage/reschedule/reschedule.component').then((m) => m.RescheduleComponent),
  },
  {
    path: 'i/:code/cancel',
    loadComponent: () => import('./screens/manage/cancel/cancel.component').then((m) => m.CancelComponent),
  },
  {
    path: 'i/:code/info',
    loadComponent: () => import('./screens/manage/edit-info/edit-info.component').then((m) => m.EditInfoComponent),
  },
  {
    path: 'i/:code/specification',
    loadComponent: () => import('./screens/manage/edit-spec/edit-spec.component').then((m) => m.EditSpecComponent),
  },
  {
    path: 'i/:code/photos',
    loadComponent: () => import('./screens/manage/photos/photos.component').then((m) => m.PhotosComponent),
  },
  {
    path: 'i/:code/eta',
    loadComponent: () => import('./screens/manage/eta-screen/eta-screen.component').then((m) => m.EtaScreenComponent),
  },
  {
    path: 'i/:code/cancel/success',
    loadComponent: () => import('./screens/success/success.component').then((m) => m.SuccessComponent),
    data: {
      message: 'was canceled successfully!',
    },
  },
  {
    path: 'i/:code/reschedule/success',
    loadComponent: () => import('./screens/success/success.component').then((m) => m.SuccessComponent),
    data: {
      message: 'was rescheduled successfully!',
      hasLink: true,
    },
  },
  {
    path: 'c/:leadId/:flowStep/:originCode',
    loadComponent: () => import('./screens/flow/continue-lead/continue-lead.component').then((m) => m.ContinueLeadComponent),
  },
  {
    path: ':flowStep/:flowType/:id',
    loadComponent: () => import('./screens/flow/donor-flow/donor-flow.component').then((m) => m.DonorFlowComponent),
  },
  {
    path: 'start/:id',
    redirectTo: 'start/charity/:id',
  },
  {
    path: 'spec/:id',
    redirectTo: 'spec/charity/:id',
  },
  {
    path: 'place-time/:id',
    redirectTo: 'place-time/charity/:id',
  },
  {
    path: 'additional-information/:id',
    redirectTo: 'additional-information/charity/:id',
  },
  {
    path: 'confirm/:id',
    redirectTo: 'confirm/charity/:id',
  },
  {
    path: 'additional-information',
    redirectTo: 'additional-information/territory/1',
  },
  {
    path: 'place-time',
    redirectTo: 'place-time/territory/1',
  },
  {
    path: 'confirm',
    redirectTo: 'confirm/territory/1',
  },
  {
    path: 'card-on-file',
    loadComponent: () => import('./screens/flow/donor-flow/donor-flow.component').then((m) => m.DonorFlowComponent),
  },
  {
    path: 'receipt/:code',
    loadComponent: () => import('./screens/receipt/receipt.component').then((m) => m.ReceiptComponent),
  },
  {
    path: 'fail',
    loadComponent: () => import('./screens/fail/fail.component').then((m) => m.FailComponent),
  },
  {
    path: 'i',
    loadComponent: () => import('./screens/donations/donations.component').then((m) => m.DonationsComponent),
  },
  {
    path: 'lookup',
    loadComponent: () => import('./screens/lookup/lookup.component').then((m) => m.LookupComponent),
  },
  {
    path: 'd/:code',
    loadComponent: () => import('./screens/confirm/confirm.component').then((m) => m.ConfirmComponent),
  },
  {
    path: 'i/:code',
    loadComponent: () => import('./screens/manage/state/state.component').then((m) => m.StateComponent),
  },
  {
    path: 'e/:code',
    loadComponent: () => import('./screens/edit/edit.component').then((m) => m.EditComponent),
  },

  {
    path: 'created/:id',
    loadComponent: () => import('./screens/created/created.component').then((m) => m.CreatedComponent),
  },
  {
    path: 'success/:code',
    loadComponent: () => import('./screens/created/created.component').then((m) => m.CreatedComponent),
  },
  {
    path: 'hc',
    loadComponent: () => import('@rspl-ui').then((m) => m.HealthCheckComponent),
  },
  {
    path: 'page-not-found',
    loadComponent: () => import('./screens/not-found/not-found.component').then((m) => m.NotFoundComponent),
  },
  {
    path: '**',
    loadComponent: () => import('./screens/not-found/not-found.component').then((m) => m.NotFoundComponent),
  },
];
