import { Inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {
  ButtonActivity,
  Design,
  Donation,
  DonationDonorState,
  DonationPartnerState,
  ENVIRONMENT,
  Environment,
  googleTagManagerPageActivities,
  InputActivity,
  Lead,
  PageActivity,
  PartOfDay,
} from '@domains';
import { IOptimizelyUserContext } from '@optimizely/optimizely-sdk/dist/optimizely_user_context';
import { DonationsService, LeadsService } from '@rspl-api';
import { DesignService } from '@rspl-ui';
import { CalendarOptions, ICalendar } from 'datebook';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

declare let gtag: (event: string, event_name: string, data?: any) => void;

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public donation?: Donation | Lead | any;
  public showCharityLogo = true;
  manualUrlParam = false;
  optimizelyUser: IOptimizelyUserContext;
  leadOrigin: 'emabtr' | 'emabrh' | 'emabrl' | 'smabtr' | 'smabrh' | 'smabrl';
  leadOriginUsed = false;

  constructor(
    private donationsService: DonationsService,
    private leadsService: LeadsService,
    private designService: DesignService,
    @Inject(ENVIRONMENT) private environment: Environment,
    protected sanitizer: DomSanitizer,
  ) {}

  reset() {
    this.donation = undefined;
  }

  saveLead(lead?: Lead): Observable<Lead> {
    const req = lead?.id ? this.leadsService.update(lead?.id, lead) : this.leadsService.create(lead || {});
    return req.pipe(
      tap((result: Lead) => {
        this.donation = result;
        this.setDesign();
      }),
    );
  }

  getLead(id: string): Observable<Lead> {
    return this.leadsService.find(id).pipe(
      tap((result: Lead) => {
        this.donation = result;
        this.setDesign();
      }),
    );
  }

  submitDonation(leadId: string): Observable<Donation> {
    return this.donationsService.convertLeadToDonation(leadId);
  }

  getDonationByCode(code: string, expand?: string[]): Observable<any> {
    return this.donationsService.getDonationByCode(code, expand).pipe(
      tap((d) => {
        this.donation = d;
        this.setDesign();
      }),
    );
  }

  updateDonationByCode(donation: Donation): Observable<Donation> {
    return this.donationsService.updateDonationByCode(donation).pipe(
      tap((d) => {
        this.donation = d;
      }),
    );
  }

  addToCalendar(donation: Donation) {
    const start = moment(donation.date);
    const end = moment(donation.date);
    if (donation.partOfDay === PartOfDay.am) {
      start.hours(8).minutes(0).seconds(0).milliseconds(0);
      end.hours(12).minutes(0).seconds(0).milliseconds(0);
    } else {
      start.hours(12).minutes(0).seconds(0).milliseconds(0);
      end.hours(17).minutes(0).seconds(0).milliseconds(0);
    }
    const config: CalendarOptions = {
      title: 'Donation: ' + donation.donationCode,
      location:
        donation?.address?.street + ', ' + donation?.address?.city + ', ' + donation?.address?.state + ', ' + donation?.address?.zip,
      description: window.location.origin + '/i/' + donation.donationCode,
      start: start.toDate(),
      end: end.toDate(),
    };
    const icalendar = new ICalendar(config);
    icalendar.download();
  }

  canEditDonation(donation?: Donation): boolean {
    return !!donation && donation.partnerState !== DonationPartnerState.completed;
  }

  canCancelDonation(donation?: Donation): boolean {
    return (
      !!donation &&
      donation.partnerState !== DonationPartnerState.completed &&
      donation.donorState !== DonationDonorState.canceled &&
      (donation.isUberPickup || !(donation.payment?.authCompleted || donation.payment?.completed))
    );
  }

  getDonationStep(donation: Donation) {
    if (
      donation.donorState &&
      [DonationDonorState.submitted, DonationDonorState.rescheduled, DonationDonorState.confirmed].includes(donation.donorState) &&
      [DonationPartnerState.unassigned, DonationPartnerState.assigned, DonationPartnerState.declined].includes(donation.partnerState)
    ) {
      return 0;
    } else if (
      donation.donorState &&
      [DonationDonorState.submitted, DonationDonorState.rescheduled, DonationDonorState.confirmed].includes(donation.donorState) &&
      [DonationPartnerState.accepted].includes(donation.partnerState)
    ) {
      return 1;
    } else if (
      donation.donorState &&
      [DonationDonorState.submitted, DonationDonorState.rescheduled, DonationDonorState.confirmed].includes(donation.donorState) &&
      [DonationPartnerState.en_routed, DonationPartnerState.arrived].includes(donation.partnerState)
    ) {
      return 2;
    } else if (
      !donation.isPaymentAuthorizedOrZeroCharge &&
      donation.partnerState === DonationPartnerState.quote_sent &&
      donation.donorState !== DonationDonorState.canceled
    ) {
      return 3;
    } else if (
      donation.isPaymentAuthorizedOrZeroCharge ||
      [
        DonationPartnerState.payment_skipped,
        DonationPartnerState.en_routed_to_store,
        DonationPartnerState.en_routed_to_secondary,
        DonationPartnerState.arrived_at_store,
        DonationPartnerState.arrived_at_secondary,
        DonationPartnerState.completed,
      ].includes(donation.partnerState)
    ) {
      return 4;
    } else {
      return 5;
    }
  }

  createLeadActivity(leadId: string | undefined | null, type: PageActivity | ButtonActivity | InputActivity, value?: any) {
    if (
      this.environment.production &&
      googleTagManagerPageActivities.includes(type as PageActivity) &&
      (this.environment.isProductionBuild || this.environment.isDevBuild || this.environment.isStagingBuild)
    ) {
      gtag('event', 'page_activity', {
        page_visited: type,
        value,
        ...(this.donation?.donor?.email || this.donation?.donor?.phone
          ? {
              lead_id: leadId,
              user_email: this.donation?.donor?.email,
              user_phone: this.donation?.donor?.phone,
            }
          : { lead_id: leadId }),
      });
    }
    if (
      Object.values(PageActivity).includes(type as PageActivity) &&
      [
        PageActivity.GOODWILL_SHOP_LANDING_PAGE,
        PageActivity.SPLIT_LANDING_PAGE,
        PageActivity.SCREENING_PAGE,
        PageActivity.DONATION_SPEC_PAGE,
        PageActivity.AVAILABILITY_PAGE,
        PageActivity.DONOR_INFO_PAGE,
        PageActivity.PAYMENT_SETUP_PAGE,
        PageActivity.SPLASH_SCREEN_PAGE,
      ].includes(type as PageActivity) &&
      this.optimizelyUser
    ) {
      this.optimizelyUser.trackEvent(type, {
        lead_id: leadId,
        activity_value: value,
      });
    }
    const origin = !this.leadOriginUsed ? this.leadOrigin : undefined;
    this.leadOriginUsed = true;
    return this.leadsService.createLeadActivity(leadId, type, value, this.isManual ? 'm-' : '', origin);
  }

  createDonationActivity(
    donationId: string | undefined | null,
    type: PageActivity | ButtonActivity | InputActivity,
    value?: any,
    origin?: string,
  ) {
    return this.donationsService.createDonationActivity(donationId, type, value, this.isManual ? 'm-' : '', origin);
  }

  get isManual(): boolean {
    return this.donation?.marketingSource?.includes('manual') || this.manualUrlParam;
  }

  private setDesign() {
    if (this.donation.charity?.meta?.design) {
      this.designService.setDesign(this.donation.charity?.meta?.design);
    } else {
      this.designService.setDesign(Design.DESIGN_2);
    }
  }
}
