import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AppType, Charity, ENVIRONMENT, Environment } from '@domains';
import { Designable, DesignService, ResponsiveService } from '@rspl-ui';
import { VersionCheckService } from '@rspl-version';
import * as moment from 'moment';
import { take } from 'rxjs/operators';

import { AppService } from './app.service';

declare let pendo: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet],
})
export class AppComponent extends Designable implements OnInit {
  versionValid?: boolean;
  isDemo: boolean;
  isStaging: boolean;
  isDev: boolean;

  title = 'rspl-donor-app';
  charity?: Charity | null;
  isReceipt = false;

  constructor(
    private versionCheck: VersionCheckService,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public service: AppService,
    private router: Router,
    protected override designService: DesignService,
    public override responsiveService: ResponsiveService,
    @Inject(ENVIRONMENT) private environment: Environment,
  ) {
    super(designService, responsiveService);
    this.isDemo = environment.isDemoBuild;
    this.isStaging = environment.isStagingBuild;
    this.isDev = environment.isDevBuild;
    this.router.getCurrentNavigation();
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.isReceipt = e.url.includes('/receipt/');
      }
    });
    moment.updateLocale('en', {
      week: {
        dow: 1,
        doy: 4,
      },
    });

    iconRegistry.addSvgIcon('rspl-small-logo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/small-logo.svg'));
    iconRegistry.addSvgIcon('rspl-new-logo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/new_logo.svg'));
    iconRegistry.addSvgIcon('white-logo', sanitizer.bypassSecurityTrustResourceUrl('assets/images/white-logo.svg'));
    iconRegistry.addSvgIcon('stripe', sanitizer.bypassSecurityTrustResourceUrl('assets/images/stripe-logo.svg'));
    iconRegistry.addSvgIcon('facebook', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/facebook.svg'));
    iconRegistry.addSvgIcon('instagram', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/instagram.svg'));
    iconRegistry.addSvgIcon('linkedin', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/linkedin.svg'));
    iconRegistry.addSvgIcon('info', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/info-icon.svg'));
    iconRegistry.addSvgIcon('warning', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/warning-icon.svg'));
    iconRegistry.addSvgIcon('checkmark', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/checkmark-icon.svg'));
    iconRegistry.addSvgIcon('lock', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/lock.svg'));
    iconRegistry.addSvgIcon('location', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/location-icon.svg'));
    iconRegistry.addSvgIcon('box', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/box-icon.svg'));
    iconRegistry.addSvgIcon('stairs', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/stairs-icon.svg'));
    iconRegistry.addSvgIcon('wrench', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/wrench-icon.svg'));
    iconRegistry.addSvgIcon('truck', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/truck-icon.svg'));
    iconRegistry.addSvgIcon('truck-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/truck-light.svg'));
    iconRegistry.addSvgIcon('glass', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/glass-icon.svg'));
    iconRegistry.addSvgIcon('prioritystar', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/prioritystar-icon.svg'));
    iconRegistry.addSvgIcon(
      'ss-primary-disassembly',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/split-screen/primary-disassembly.svg'),
    );
    iconRegistry.addSvgIcon('ss-primary-floor', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/split-screen/primary-floor.svg'));
    iconRegistry.addSvgIcon('ss-primary-items', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/split-screen/primary-items.svg'));
    iconRegistry.addSvgIcon('ss-primary-time', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/split-screen/primary-time.svg'));
    iconRegistry.addSvgIcon(
      'ss-standard-disassembly',
      sanitizer.bypassSecurityTrustResourceUrl('assets/icons/split-screen/standard-disassembly.svg'),
    );
    iconRegistry.addSvgIcon('ss-standard-floor', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/split-screen/standard-floor.svg'));
    iconRegistry.addSvgIcon('ss-standard-items', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/split-screen/standard-items.svg'));
    iconRegistry.addSvgIcon('ss-standard-time', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/split-screen/standard-time.svg'));
    iconRegistry.addSvgIcon('parachute-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/parachute-box-light.svg'));
    iconRegistry.addSvgIcon('close-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_close.svg'));
    iconRegistry.addSvgIcon('edit-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_edit.svg'));
    iconRegistry.addSvgIcon('edit-icon-blue', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_edit_blue.svg'));
    iconRegistry.addSvgIcon('calendar-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_calendar.svg'));
    iconRegistry.addSvgIcon('success-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_success.svg'));
    iconRegistry.addSvgIcon('camera-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/ic_camera.svg'));
    iconRegistry.addSvgIcon('ss3-truck', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/truck.svg'));
    iconRegistry.addSvgIcon('ss3-wrench', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/wrench.svg'));
    iconRegistry.addSvgIcon('ss3-stairs', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/stairs.svg'));
    iconRegistry.addSvgIcon('ss3-dollar', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/dollar.svg'));
    iconRegistry.addSvgIcon('ss3-percent', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/percent.svg'));
    iconRegistry.addSvgIcon('ss3-play', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/play.svg'));
    iconRegistry.addSvgIcon('ss3-arrow-right', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/arrow-right.svg'));
    iconRegistry.addSvgIcon(
      'ss3-arrow-right-blue',
      sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/arrow-right-blue.svg'),
    );
    iconRegistry.addSvgIcon('ss3-google', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/google.svg'));
    iconRegistry.addSvgIcon('ss3-check', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/check.svg'));
    iconRegistry.addSvgIcon('ss3-email', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/email.svg'));
    iconRegistry.addSvgIcon('ss3-chat', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/chat.svg'));
    iconRegistry.addSvgIcon('ss3-sms', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/sms.svg'));
    iconRegistry.addSvgIcon('ss3-minus-circle', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/minus-circle.svg'));
    iconRegistry.addSvgIcon('ss3-plus-circle', sanitizer.bypassSecurityTrustResourceUrl('assets/split-screen-v3/plus-circle.svg'));
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.versionCheck
      .initVersionCheck()
      .pipe(take(1))
      .subscribe(() => {
        this.versionValid = true;
      });
    if (this.environment.isProductionBuild) {
      pendo.initialize({
        visitor: {
          id: 'donor',
          application_type: AppType.DONOR,
        },
        account: {
          id: 'ACCOUNT-UNIQUE-ID',
        },
      });
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent): void {
    const w = event.target as Window;
    this.responsiveService.setWindowSize(w.innerWidth);
  }
}
