@if (isStaging || isDemo || isDev) {
  <div class="header-staging">
    {{ isDemo ? 'demo' : isDev ? 'dev' : 'staging' }}
  </div>
}
@if (versionValid && !isReceipt) {
  <div class="content">
    <router-outlet></router-outlet>
  </div>
}
@if (versionValid && isReceipt) {
  <router-outlet></router-outlet>
}
