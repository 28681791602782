import { CurrencyPipe } from '@angular/common';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { createErrorHandlerFactory } from '@appsignal/angular';
import Appsignal from '@appsignal/javascript';
import { plugin as pluginPathDecorator } from '@appsignal/plugin-path-decorator';
import { plugin as pluginWindowEvent } from '@appsignal/plugin-window-events';
import { ENVIRONMENT } from '@domains';
import { provideRsplUIProviders } from '@rspl-ui';
import { provideRsplVersion } from '@rspl-version';
import { StarRatingModule } from 'angular-star-rating';
import { LaddaModule } from 'angular2-ladda';
import { CountdownModule } from 'ngx-countdown';
import { NgxCurrencyDirective, NgxCurrencyInputMode, provideEnvironmentNgxCurrency } from 'ngx-currency';
import { provideNgxMask } from 'ngx-mask';
import { PixelModule } from 'ngx-pixel-eventid';
import { NgxStripeModule } from 'ngx-stripe';

import { AppComponent } from './app/app.component';
import { appRoutes } from './app/app.routes';
import { environment } from './environments/environment';

const appsignal = new Appsignal({
  key: environment.appSignal,
  namespace: 'donor',
  revision: '{{POST_BUILD_ENTERS_APP_SIGNAL_HASH_HERE}}',
});

appsignal.use(pluginPathDecorator());
appsignal.use(pluginWindowEvent());

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideHttpClient(withInterceptorsFromDi()),
    provideRsplUIProviders(),
    provideRsplVersion({ currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}' }),
    provideRouter(appRoutes, withComponentInputBinding()),
    importProvidersFrom(
      BrowserModule,
      LaddaModule.forRoot({
        style: 'zoom-in',
      }),
      StarRatingModule.forRoot(),
      NgxCurrencyDirective,
      PixelModule.forRoot({
        enabled: environment.production,
        pixelId: '271975238257416',
      }),
      NgxStripeModule.forRoot(environment.stripeKey),
      CountdownModule,
    ),
    CurrencyPipe,
    ...(environment.isProductionBuild || environment.isStagingBuild || environment.isDevBuild
      ? [
          {
            provide: ErrorHandler,
            useFactory: createErrorHandlerFactory(appsignal),
          },
        ]
      : []),
    { provide: ENVIRONMENT, useValue: environment },
    provideNgxMask(),
    provideEnvironmentNgxCurrency({
      align: 'left',
      allowNegative: false,
      allowZero: true,
      decimal: '.',
      precision: 2,
      prefix: '$ ',
      suffix: '',
      thousands: ',',
      nullable: false,
      min: 0,
      inputMode: NgxCurrencyInputMode.Natural,
    }),
    provideAnimations(),
  ],
}).catch((err) => console.error(err));
